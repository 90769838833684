import React from 'react'
import {Helmet} from 'react-helmet'
import ME from '../../assets/me.png'

const CTA = () => {

  const ogData = {
    title: 'Gulrez Alam | CV',
    description: 'Download Gulrez Alam CV to learn more about my work experience and education.',
    image: ME,
    url: 'https://1drv.ms/b/s!Ajy2DojKmI_jg91-8t1wg_K8Y2faow?e=echebW'
  }

  return (
    <div className='cta'>
      <Helmet>
        <title>{ogData.title}</title>
        <meta name='description' content={ogData.description} />
        <meta property='og:title' content={ogData.title} />
        <meta property='og:description' content={ogData.description} />
        <meta property='og:image' content={ogData.image} />
        <meta property='og:url' content={ogData.url} />
      </Helmet>
      
        <a href='https://1drv.ms/b/s!Ajy2DojKmI_jg91-8t1wg_K8Y2faow?e=echebW' className='btn' target='_blank' rel="noreferrer">Download CV</a>
        <a href="#contact" className='btn btn-primary'>Let's Talk</a>
    </div>
  )
}

 export default CTA
